import React from 'react';
import PropTypes from 'prop-types';

function OTLogo({
  color = '#000000',
  width = 280,
  height = 80,
}) {
  return (
    <svg preserveAspectRatio="xMidYMid meet" width={`${width}px`} height={`${height}px`} viewBox="0 0 407 141">
      <title>Overtone Labs Email</title>
      <g id="Overtone-Labs-Email" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Logo-2" transform="translate(14.000000, 19.000000)" stroke={color}>
          <path d="M1.57802016,52.2041088 C1.57802016,52.2041088 28.1692754,34.1589855 55.1474373,34.2313624 C82.1255992,34.3037392 108.716854,52.4915403 108.716854,52.4915403" id="Path-3" strokeWidth="10" opacity="0.3" />
          <path d="M1.41025641,72.8898431 C1.41025641,72.8898431 28.0015117,55.5424726 54.9796735,55.6120508 C81.9578354,55.6816291 108.549091,73.1661605 108.549091,73.1661605" id="Path-3" strokeWidth="8" opacity="0.3" transform="translate(54.979674, 64.389001) scale(1, -1) translate(-54.979674, -64.389001) " />
          <path d="M1.41025641,53.2054244 C1.41025641,53.2054244 25.1587726,94.1178887 54.9796735,53.8137242 C84.8005745,13.5095598 108.549091,53.8158593 108.549091,53.8158593" id="Path-2" strokeWidth="9" opacity="0.769999981" />
          <path d="M1.41025641,53.2054244 C1.41025641,53.2054244 25.1587726,94.1178887 54.9796735,53.8137242 C84.8005745,13.5095598 108.549091,53.8158593 108.549091,53.8158593" id="Path-2" strokeWidth="9" transform="translate(54.979674, 53.735822) scale(1, -1) translate(-54.979674, -53.735822) " />
          <ellipse id="Oval" strokeWidth="9" cx="55" cy="53.5" rx="53.5897436" ry="53.5" />
        </g>
        <text id="Overtone" fontFamily="Avenir-Medium, Avenir" fontSize="58" fontWeight="400" letterSpacing="1.27999997" fill={color}>
          <tspan x="136" y="65">Ove</tspan>
          <tspan x="249.344" y="65" letterSpacing="4.08">rt</tspan>
          <tspan x="300.424" y="65">on</tspan>
          <tspan x="369.684" y="65" letterSpacing="0.07894737">e</tspan>
        </text>
        <text id="Labs" opacity="0.396342844" fontFamily="Avenir-Book, Avenir" fontSize="50" fontWeight="normal" fill={color}>
          <tspan x="135" y="114">Labs</tspan>
        </text>
      </g>
    </svg>
  );
}

OTLogo.propTypes = {
  color: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
};

OTLogo.defaultProps = {
  width: 280,
  height: 80,
};

export default OTLogo;
