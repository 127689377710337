import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { hexColorWithOpacity } from '../utilities/OTColors';
import { selectAchievables } from '../selectors/roadmap';
import { updateAchievables } from '../reducers/roadmapSlice';
import { themeColor } from '../Constants';

const Table = styled.table`
  font-family: Avenir, sans-serif;
  border-spacing: 0;
  width: ${(props) => `${props.width}px`};
  border-top-left-radius: 8px;
  backdrop-filter: blur(2px);
`;

const TableRowDark = styled.tr`
  background-color: ${themeColor};
  color: ${hexColorWithOpacity('#ffffff', 0.9)};
`;

const TableRowTransparent = styled.tr`
  background-color: ${hexColorWithOpacity(themeColor, 0.01)};
  color: ${themeColor};
`;

const TableRow = styled.tr`
  background-color: ${(props) => (props.isDark ? hexColorWithOpacity(themeColor, 0.75) : hexColorWithOpacity(themeColor, 0.5))};
  color: ${themeColor};
`;

const TableHeader = styled.th`
  font-size: 20px;
  height: 60px;
  padding-left: 20px;
  text-align: left;
`;

const TableSubHeader = styled.th`
  font-size: 20px;
  height: 40px;
  padding-left: 20px;
  text-align: left;
  color: ${themeColor};
  background-color: ${hexColorWithOpacity('#ffffff', 0.6)};
  border-bottom: 3px solid ${themeColor};
  border-left: 3px solid ${themeColor};
  border-right: 3px solid ${themeColor};
`;

const TableData = styled.td`
  border-bottom: 3px solid ${themeColor};
  border-left: 3px solid ${themeColor};
  border-right: 3px solid ${themeColor};
  border-bottom-left-radius: ${(props) => (props.roundCorners ? '12px' : '0px')};
  color: ${themeColor};
  font-size:16px;
  height: 30px;
  background-color: ${hexColorWithOpacity('#ffffff', 0.55)};
`;

const TextInput = styled.input`
  font-size: 16px;
  font-family: Avenir;
  border: none;
  color: ${themeColor};
  width: 93%;
  padding: 4px;
  font-weight: bold;
  background: transparent;
  :focus,
  :active {
    background: transparent;
  }
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${hexColorWithOpacity(themeColor, 0.2)};
    opacity: 1; /* Firefox */
  }

`;

function OTPlannerHeader({ width = 100, nRows = 2 }) {
  useEffect(() => {
  }, []);
  const achievables = useSelector(selectAchievables);
  const dispatch = useDispatch();
  const tableRows = [];
  for (let i = 0; i < nRows; i += 1) {
    tableRows.push(
      <TableRow
        isDark={(i % 2 === 0)}
        key={`key_${i}`}
      >
        <TableData roundCorners={i === (nRows - 1)}>
          <TextInput
            type="text"
            id="key_input"
            name="key_input"
            value={achievables[i]}
            placeholder="Enter Achievable"
            onChange={(e) => {
              const updatedAchievable = e.target.value;
              const newAchievables = JSON.parse(JSON.stringify(achievables));
              newAchievables[i] = updatedAchievable;
              dispatch(updateAchievables(
                {
                  achievables: newAchievables,
                },
              ));
            }}
          />
        </TableData>
      </TableRow>,
    );
  }

  return (
    <div style={{ display: 'inline-block' }}>
      <Table width={width}>
        <thead>
          <TableRowDark>
            <TableHeader style={{ borderTopLeftRadius: 12 }}>Weekly Roadmap</TableHeader>
          </TableRowDark>
          <TableRowTransparent>
            <TableSubHeader>Achievables</TableSubHeader>
          </TableRowTransparent>
        </thead>
        <tbody>
          {tableRows}
        </tbody>
      </Table>
    </div>
  );
}

OTPlannerHeader.propTypes = {
  width: PropTypes.number.isRequired,
  nRows: PropTypes.number.isRequired,
};

export default OTPlannerHeader;
