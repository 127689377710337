import { createSlice } from '@reduxjs/toolkit';
import { numberOfAchievableRows, numberOfDaysInAWeek } from '../Constants';

export const initialWeekState = Array.from({
  length: numberOfAchievableRows,
}, () => Array(numberOfDaysInAWeek).fill(0));
export const initialAchievableState = Array(numberOfAchievableRows).fill('');

export const initialWeightState = [];

export const roadmapSlice = createSlice({
  name: 'core',
  initialState: {
    achievables: initialAchievableState,
    roadmap: [initialWeekState, initialWeekState, initialWeekState, initialWeekState],
    weight: initialWeightState,
  },
  reducers: {
    clearWeight: (state) => ({
      ...state,
      weight: initialWeightState,
    }),
    clearRoadmap: (state) => ({
      ...state,
      roadmap: [initialWeekState, initialWeekState, initialWeekState, initialWeekState],
    }),
    clearAchievables: (state) => ({
      ...state,
      achievables: initialAchievableState,
    }),
    clearAll: (state) => ({
      ...state,
      achievables: initialAchievableState,
      roadmap: [initialWeekState, initialWeekState, initialWeekState, initialWeekState],
    }),
    updateRoadmap: (state, action) => ({
      ...state,
      roadmap: action.payload.roadmap,
    }),
    updateAchievables: (state, action) => ({
      ...state,
      achievables: action.payload.achievables,
    }),
    updateWeight: (state, action) => ({
      ...state,
      weight: action.payload.weight,
    }),
  },
});

export const {
  clearRoadmap,
  clearAchievables,
  clearAll,
  updateRoadmap,
  updateAchievables,
  clearWeight,
  updateWeight,
} = roadmapSlice.actions;
export default roadmapSlice.reducer;
