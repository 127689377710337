import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { hexColorWithOpacity } from '../utilities/OTColors';
import { selectRoadmap } from '../selectors/roadmap';
import { updateRoadmap } from '../reducers/roadmapSlice';
import { themeColor } from '../Constants';

const Table = styled.table`
  font-family: Avenir, sans-serif;
  border-spacing: 0;
  width: ${(props) => `${props.width}px`};
  border-left:2px solid ${themeColor};
  backdrop-filter: blur(2px);
`;

const TableRowTransparent = styled.tr`
  background-color: ${hexColorWithOpacity(themeColor, 0.0)};
  color: ${themeColor};
`;

const TableRowDatesHeader = styled.tr`
  background-color: ${hexColorWithOpacity('#ffffff', 0.4)};
  color: ${themeColor};
  height: 45px;
`;

const TableRow = styled.tr`
  background-color: ${(props) => (props.isDark ? hexColorWithOpacity(themeColor, 0.6) : hexColorWithOpacity(themeColor, 0.5))};
  color: ${themeColor};
  border-bottom-right-radius: ${(props) => (props.roundCorners ? '12px' : '0px')};
`;

const TableHeaderDate = styled.th`
  font-size: 20px;
  height: 54px;
  text-align: center;
  border: 3px solid ${themeColor};
  border-left: 0 solid ${themeColor};
  background-color: ${hexColorWithOpacity('#ffffff', 0.6)};
  border-top-right-radius: ${(props) => (props.roundCorners ? '12px' : '0px')};
`;

const DateRange = styled.div`
  font-size: 16px;
  text-align: center;
`;

const TableDataDate = styled.td`
  border-right: 3px solid ${themeColor};
  color: ${themeColor};
  cursor: pointer;
  font-size:16px;
  width:20px;
  height: 30px;
  border-bottom: solid 3px ${themeColor};
  border-bottom-right-radius: ${(props) => (props.roundCorners ? '12px' : '0px')};
  background-color:${hexColorWithOpacity('#ffffff', 0.5)};
`;

function OTPlannerBlock({
  width = 100,
  nRows = 2,
  monday = Date.parse('2021-12-27'),
  week = 1,
}) {
  useEffect(() => {
  }, []);

  const roadmap = useSelector(selectRoadmap);

  const dispatch = useDispatch();

  const toggleAchievableStatus = (pWeek, pDay, pAchievableIndex) => {
    const newRoadMap = JSON.parse(JSON.stringify(roadmap));
    const currentValue = Boolean(newRoadMap[pWeek][pAchievableIndex][pDay]);
    const newValue = !currentValue ? 1 : 0;
    newRoadMap[pWeek][pAchievableIndex][pDay] = newValue;
    dispatch(updateRoadmap({
      roadmap: newRoadMap,
    }));
  };

  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const pMondayDate = new Date(monday);

  const pSundayDate = new Date(monday);
  pSundayDate.setDate(pSundayDate.getDate() + 6);

  const year = pSundayDate.getFullYear();

  const month1 = pMondayDate.getMonth();
  const month2 = pSundayDate.getMonth();

  const day1 = pMondayDate.getDate();
  const day2 = pSundayDate.getDate();

  const dateString = `${months[month1]} ${day1} - ${months[month2]} ${day2}, ${year}`;

  const tableRows = [];
  const weekRoadMap = roadmap[week - 1];
  const daysArray = ['M', 'T', 'W', 'Th', 'F', 'Sa', 'Su'];
  for (let i = 0; i < nRows; i += 1) {
    const achievableColumn = weekRoadMap[i];
    tableRows.push(
      <TableRow
        isDark={(i % 2 === 0)}
        roundCorners={(i === nRows - 1) && (week === 4)}
        key={`key_data_${i}`}
      >
        {daysArray.map((day) => {
          const dayIndex = daysArray.indexOf(day);
          return (
            <TableDataDate
              key={`key_data_date_value_${day}`}
              roundCorners={i === nRows - 1 && week === 4 && day === 'Su'}
              onClick={() => {
                toggleAchievableStatus(week - 1, dayIndex, i);
              }}
            >
              {achievableColumn[dayIndex] === 1 ? '✅' : null}
            </TableDataDate>
          );
        })}
      </TableRow>,
    );
  }

  return (
    <div style={{ display: 'inline-block' }}>
      <Table width={width}>
        <thead>
          <TableRowTransparent>
            <TableHeaderDate colSpan="7" roundCorners={(week === 4)}>
              <b>
                WEEK
                &nbsp;
                {week}
              </b>
              <DateRange>{dateString}</DateRange>
            </TableHeaderDate>
          </TableRowTransparent>
          <TableRowDatesHeader>
            {
              daysArray.map((day) => (
                <TableDataDate
                  key={`key_data_day_${day}`}
                >
                  <b>{day}</b>
                </TableDataDate>
              ))
            }
          </TableRowDatesHeader>
        </thead>
        <tbody>
          {tableRows}
        </tbody>
      </Table>
    </div>
  );
}

OTPlannerBlock.propTypes = {
  width: PropTypes.number.isRequired,
  nRows: PropTypes.number.isRequired,
  monday: PropTypes.instanceOf(Date).isRequired,
  week: PropTypes.number.isRequired,
};

export default OTPlannerBlock;
