import './App.css';
import React from 'react';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { configureStore } from '@reduxjs/toolkit';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import OTPlanner from './Components/OTPlanner';
import coreReducer from './reducers/roadmapSlice';

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer(persistConfig, coreReducer);
const store = configureStore({
  reducer: persistedReducer,
});
const persistor = persistStore(store);

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <div className="App">
          <OTPlanner />
        </div>
      </PersistGate>
    </Provider>
  );
}

export default App;
