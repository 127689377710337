import { initialWeekState, initialWeightState } from '../reducers/roadmapSlice';

export const selectAchievables = (state) => {
  if (state.achievables) {
    return state.achievables;
  }
  return ['', '', '', '', '', '', '', '', ''];
};

export const selectRoadmap = (state) => {
  if (state.roadmap) {
    return state.roadmap;
  }
  return [initialWeekState, initialWeekState, initialWeekState, initialWeekState];
};

export const selectWeight = (state) => {
  if (state.weight) {
    return state.weight;
  }
  return initialWeightState;
};
