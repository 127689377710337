import React from 'react';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import { hexColorWithOpacity } from '../utilities/OTColors';

const TooltipHolder = styled.div`
  background-color: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(4px);
  padding: 10px;
  max-width: 300px;
  border-radius: 6px;
  border: solid 1px ${hexColorWithOpacity('#000000', 0.25)};
`;

const Label = styled.div`
  color: rgba(0,0,0,0.75);
`;

const DateLabel = styled.div`
  color: rgba(0,0,0,0.55);
  font-size: 14px;
`;

function GraphToolTip({
  toolTipLabel,
  payload,
  dataKey,
}) {
  let timeMS = 0;
  let value;
  const stdValue = undefined;

  console.log(payload);

  if (payload && payload[0] && payload[0].payload) {
    timeMS = payload[0].payload.date;
    value = payload[0].payload[`${dataKey}`];
  }
  const timeLabel = DateTime.fromMillis(timeMS).toFormat('MMMM dd, yyyy hh:mm a');
  const numberValue = Number(value);

  let valueString = numberValue
    ? `${numberValue.toFixed(2)}`
    : '';
  if (stdValue) {
    valueString = `${numberValue.toFixed(2)} ± ${stdValue?.toFixed(2)} STD`;
  }

  return (
    <TooltipHolder>
      {payload
				&& (
<div>
  <Label>
    <b>
      {toolTipLabel}
      :
    </b>
    {' '}
    {valueString}
  </Label>
  <DateLabel>{`${timeLabel}`}</DateLabel>
</div>
				)}
    </TooltipHolder>
  );
}

GraphToolTip.propTypes = {
  toolTipLabel: PropTypes.string.isRequired,
  dataKey: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  payload: PropTypes.object.isRequired,
};
export default GraphToolTip;
