import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
} from 'recharts';
import { useDispatch, useSelector } from 'react-redux';
import { selectWeight } from '../selectors/roadmap';
import { updateWeight } from '../reducers/roadmapSlice';
import { hexColorWithOpacity } from '../utilities/OTColors';
import { themeColor } from '../Constants';
import GraphToolTip from './GraphTooltip';

const Wrapper = styled.div`
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(2px);
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin: 10px auto 30px auto;
  font-family: Avenir, sans-serif;
	position: relative;
	overflow: clip;
	border: 3px solid ${themeColor};
`;

const Title = styled.div`
	font-weight: bold;
	font-size: 20px;
	color: ${themeColor};
`;

const AddButton = styled.div`
	position: absolute;
	top: 10px;
	right: 10px;
	width: 30px;
	height: 30px;
	border-radius: 40px;
	color: white;
	cursor: pointer;
	font-size: 22px;
	font-weight: bold;
	background-color: ${hexColorWithOpacity(themeColor, 0.85)};
	display: flex;
	align-items: center;
	justify-content: center;
`;

const CloseButton = styled(AddButton)`
  transform: rotate(45deg);
  background-color: ${hexColorWithOpacity(themeColor, 0.7)};
`;

const AddEntryModalCurtain = styled.div`
	background-color: rgba(0,0,0,0.8);
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const AddEntryModal = styled.div`
	width: 260px;
	height: auto;
	padding: 10px;
	border-radius: 10px;
	background-color: white;
	position: relative;
`;

const WeightFieldHolder = styled.div`
	margin-top: 10px;
`;

const WeightField = styled.input`
  width: 80px;
  font-size: 16px;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
`;

const AddWeightButton = styled.div`
  font-weight: bold;
  font-size: 16px;
	color: white;
	margin-top: 10px;
	background-color: ${hexColorWithOpacity(themeColor, 0.85)};
	cursor: pointer;
	border-radius: 40px;
	padding: 10px 26px;
	display: inline-flex;
`;

function OTWeightTracker({ width = 400, height = 350 }) {
  useEffect(() => {
  }, []);
  const dispatch = useDispatch();

  const [showAddEntryModal, setShowAddEntryModal] = useState(false);
  const [weightEntry, setWeightEntry] = useState(undefined);

  const weightData = useSelector(selectWeight);

  const processedGraphData = weightData.map((item) => ({
    weight: item.weight,
    date: DateTime.fromISO(item.date).toMillis(),
  }));

  const weightValues = weightData.map((item) => item.weight);
  const graphMaxPoint = Math.max(...weightValues);
  const graphMinPoint = Math.min(...weightValues);
  const yDomainTop = graphMaxPoint + 2;
  const yDomainBottom = Math.max(0, graphMinPoint - 2);

  const formatYTick = (tickItem) => tickItem.toFixed(2);
  const formatXAxisDate = (tickItem) => DateTime.fromMillis(tickItem).toFormat('MMM dd');

  return (
    <Wrapper width={width} height={height}>

      <Title>Weight Tracker</Title>
      <AddButton
        onClick={() => {
          setShowAddEntryModal(true);
        }}
      >
        +
      </AddButton>
      {(!weightData || weightData.length === 0) && <div>Enter weight data to start tracking</div>}
      { (weightData && weightData.length > 0) && (
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={processedGraphData}
          margin={{
            top: 10,
            right: 20,
            left: 30,
            bottom: 14,
          }}
        >

          <CartesianGrid strokeDasharray="3 3" />

          <XAxis
            angle={0}
            tickFormatter={formatXAxisDate}
            domain={['auto', 'auto']}
            type="number"
            dataKey="date"
            scale="time"
            label={{
              value: 'Date',
              dy: 18,
              fontFamily: 'Avenir, sans-serif',
              fontSize: '14px',
              fill: 'rgba(0,0,0,0.8)',
            }}
            style={{
              fontSize: '11px', fontFamily: 'Avenir, sans-serif',
            }}
          />

          <YAxis
            dataKey="weight"
            width={40}
            type="number"
            domain={[yDomainBottom, yDomainTop]}
            tickFormatter={formatYTick}
            label={{
              value: 'Weight (lbs)',
              angle: -90,
              dx: -35,
              fontSize: '14px',
              fill: 'rgba(0,0,0,0.8)',
            }}
            style={{ fontSize: '10px', fontFamily: 'Avenir, sans-serif' }}
          />
          <Tooltip
            content={(
              <GraphToolTip
                dataKey="weight"
                toolTipLabel="Weight (lbs)"
              />
         )}
          />
          <Line
            type="monotone"
            dataKey="weight"
            stroke={themeColor}
            strokeWidth={2}
            activeDot={{
              r: 8,
              onClick: (dotProps, e) => {
                const dotIndex = e.index;
                if (window.confirm('Do you want to delete this data point?')) {
                  const updatedWeightData = [...weightData];
                  updatedWeightData.splice(dotIndex, 1);
                  dispatch(updateWeight({ weight: updatedWeightData }));
                }
              },
            }}
          />
        </LineChart>
      </ResponsiveContainer>
      )}
      { showAddEntryModal && (
      <AddEntryModalCurtain>
        <AddEntryModal>
          <CloseButton
            onClick={() => {
              setShowAddEntryModal(false);
            }}
          >
            +
          </CloseButton>
          <Title>Add Entry</Title>
          <WeightFieldHolder>
            <WeightField
              value={weightEntry}
              type="number"
              onChange={(e) => {
                const floatValue = parseFloat(e.target.value);
                if (floatValue < 0) {
                  setWeightEntry(undefined);
                  return;
                }setWeightEntry(floatValue);
              }}
            />
            {' '}
            lbs
          </WeightFieldHolder>
          <AddWeightButton
            onClick={() => {
              if (weightEntry !== undefined) {
                const newWeightEntry = {
                  weight: weightEntry,
                  date: DateTime.now().toISO(),
                };
                dispatch(updateWeight({
                  weight: [...weightData, newWeightEntry],
                }));
                setShowAddEntryModal(false);
                setWeightEntry(undefined);
              }
            }}
          >
            Add
          </AddWeightButton>
        </AddEntryModal>
      </AddEntryModalCurtain>
      )}
    </Wrapper>
  );
}

OTWeightTracker.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default OTWeightTracker;
